<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner">
      <b-link class="brand-logo">
        <b-img class="w-50" :src="GADAILogo" alt="Teppanku Logo" />
      </b-link>

      <b-card class="mb-0">
        <b-card-title class="mb-50">
          <h4 class="text-danger">TEPPANKU</h4>
        </b-card-title>

        <b-card-text class="mb-2 text-dark">
          <span>Silahkan login menggunakan akun anda</span>
        </b-card-text>

        <b-alert variant="danger" :show="showAlert">
          <div class="alert-body text-center">
            <span>{{ errorMessage }}</span>
          </div>
        </b-alert>

        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2 pb-50" @submit.prevent="login">
            <vue-form-json-schema v-model="model" :schema="schema" :ui-schema="uiSchema" />
            <b-button variant="danger" type="submit" block :disabled="invalid">
              <div class="d-flex align-items-center justify-content-center">
                <b-spinner v-if="!loading" variant="light" label="Spinning" />
                <div class="pl-1">Login</div>
              </div>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { BButton, BForm, BCard, BLink, BCardTitle, BCardText, BImg, BAlert, BSpinner, BIconEmojiNeutralFill } from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import GADAILogo from '@/assets/images/logo/teppanku.png'
import CollectiumLogo from '@/assets/images/logo/teppanku.png'
import SkyworxLogo from '@/assets/images/logo/skyworx-2.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    ValidationObserver,
    BImg,
    BAlert,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      loading: true,
      userEmail: '',
      userName: '',
      password: '',
      status: '',
      required,
      GADAILogo,
      CollectiumLogo,
      SkyworxLogo,
      userNameError: false,
      passwordError: false,
      showAlert: false,
      model: { userName: '', password: '' },
      schema: {
        type: 'object',
        properties: {
          userName: { type: 'string' },
          password: { type: 'string' },
        },
      },
      errorMessage: '',
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },

    uiSchema() {
      const data = [{
        component: 'b-form-group',
        fieldOptions: {
          props: { label: 'Username', 'label-for': 'username' },
          class: `${this.userNameError || this.showAlert ? 'text-danger' : ''}`
        },
        children: [{
          component: 'b-form-input',
          model: 'userName',
          fieldOptions: {
            placeholder: 'Masukkan username',
            props: { id: 'username', name: 'login-username', autofocus: true },
            on: 'change'
          }
        }]
      }, {
        component: 'b-form-group',
        fieldOptions: {
          props: { label: 'Password', 'label-for': 'password' },
          class: `py-25 ${this.userNameError || this.showAlert ? 'text-danger' : ''}`,
        },
        children: [{
          component: 'b-input-group',
          fieldOptions: { class: 'input-group-merge' },
          children: [{
            component: 'b-form-input',
            model: 'password',
            fieldOptions: {
              attrs: { type: this.passwordFieldType },
              props: { id: 'password', name: 'login-password' },
              class: 'form-control-merge',
              placeholder: 'Password',
              on: 'change'
            }
          }, {
            component: 'b-input-group-append',
            fieldOptions: {
              props: { 'is-text': true },
            },
            children: [{
              component: 'feather-icon',
              fieldOptions: {
                props: { icon: this.passwordToggleIcon, size: '14' },
                class: 'cursor-pointer',
                on: { click: this.togglePasswordVisibility }
              }
            }]
          }]
        }]
      }]
      return data
    }
  },

  mounted() {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      this.getProfile()
    }
  },

  methods: {
    getProfile() {
      this.$http.get('/api/user').then(response => {
        if (response == null) return
        const [data] = response?.data?.data
        localStorage.setItem('userData', JSON.stringify(data))

        this.$ability.update([
          { action: 'manage', subject: 'all' }
        ])

        this.$router.replace('/').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Welcome',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Welcome',
            },
          })
        })
      })
    },

    login() {
      this.loading = false;
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({ username: this.model.userName, login: this.model.password }).then(response => {
            if (response.status === 401) {
              this.errorMessage = response.data.message + ', silakan hubungi administrator';
              this.showAlert = true;
              this.loading = true;
            } else if (!response.data.status) {
              this.errorMessage = response.data.message + ', silakan hubungi administrator';
              this.showAlert = true;
              this.loading = true;
            } else {
              const [token] = response.data.data;
              useJwt.setToken(token);
              useJwt.setRefreshToken(token);
              window.location.reload()
              this.loading = false;
              return BIconEmojiNeutralFill;
            }
          }).catch(error => {
            console.error('Error during login:', error);
            this.errorMessage = error;
            this.showAlert = true;
            this.loading = true;
          });
        }
      });
    },

    userNameHandler(errors) {
      let val = false
      if (errors.length > 0) val = true
      this.userNameError = val
      return val
    },

    passwordHandler(errors) {
      let val = false
      if (errors.length > 0) val = true
      this.passwordError = val
      return val
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

body {
  background-color: #F8F8F8 !important;
}

.card-body {
  padding-bottom: 20px;
}

.auth-inner {
  padding-top: 0;
  padding-bottom: 5.5rem;

  &::before,
  &::after {
    display: none;
  }
}

footer.footer {
  position: absolute;
  bottom: 0%;
  padding-bottom: 25px;

  .footer-text {
    margin-top: 20px;
  }
}
</style>
